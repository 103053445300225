import "./assets/css/App.css";
import React, { useState, useEffect } from 'react'
import Slider from "./components/Slider";
import { Topbar } from "./components/Topbar";

function App() {

  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
        {data !== null ? (
          <div className="App">
            <Topbar data={data}/>
            <Slider/>
          </div>
        ):(<></>)}
    </>
  );
}

export default App;
