import React from 'react'
import promo from '../assets/images/hogarprotegido-desktop.png'
import promoMobile from '../assets/images/hogarprotegido-mobile.png'

const Slider = () => {
  return (
    <div className='carousel'>
      {window.innerWidth <= 1001? (
        <img
          src={promoMobile}
          alt="promocionMobile"
          style={{ width: "100%", height: "100%" }}
        />
      ):(
        <img
          src={promo}
          alt="promocion"
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  )
}

export default Slider