import React from "react";
import "../assets/css/topbar.css";
import phoneIcon from "../assets/images/phone-number.svg";
import logo from "../assets/images/logo.png";

export const Topbar = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  return (
    <div>
      {window.innerWidth <= 1000 ?(
        <div className="topbar-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="floating">
            <div className="floating-button" type="button">
              <img src={phoneIcon} alt="Phone" className="phoneIcon" />
              <a href={`tel:${phone}`}>
                <b>{numberIcon}</b>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="topbar-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="floating-promo">
            <h2>Quiero la promo</h2>
          </div>
          <div className="floating">
            <div className="floating-button" type="button">
              <img src={phoneIcon} alt="Phone" className="phoneIcon" />
              <a href={`tel:${phone}`}>
                <b>{numberIcon}</b>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
